import React from "react";
import { graphql } from 'gatsby';
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";

const NotFound = ({ data }) => {

  const header = data.header.data;
  const footer = data.footer.data;

  const styles = {
    paddingTop: "300px",
    paddingBottom: "200px",
    textAlign: "center",
    borderBottom: "1px solid #121112",
  }

  return (
    <Layout headerStyle="light" header={header} footer={footer}>
      <Seo title="404: Not found" />
      <div style={styles}>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  )
}

export const query = graphql`
{
  header: prismicNavigation {
      ...FragmentHeader
  }

	footer: prismicFooter {
		...FragmentFooter
	}
}
`

export default NotFound